<div class="row">
    <div class="col-lg-12 offset-lg-1">
        <h2 id="subheader" class="sub-header">Oops!</h2>
        <p id="apologize_text" class="message-content">
            Like glasses, links occasionally get lost or broken. We apologize for the inconvenience.
        </p>
        <p id="suggestion_text" class="message-content">
            This content may have been moved, no longer exists, or is currently unavailable. We suggest refreshing the
            page, clicking the “back” button on your browser to return to the previous page, or visiting the
            <a routerLink="">Reference Inventory</a> page.
        </p>
    </div>
</div>
