import { RefInvPortalAuthService } from './ref-inv-portal-auth.service';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(private authenticationService: RefInvPortalAuthService) { }
  
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      if (sessionStorage && sessionStorage.getItem('state-url') === null) {
        sessionStorage.setItem('state-url', state.url);
      } else {
        // check if we're already logged in, if so move on
        if (this.authenticationService.loggedIn) {
          return observableOf(true);
        }
      }
  
      // check if page load or router navigation, then login if appropriate
      return this.authenticationService.checkAuthentication(state.url);
    }
  }
