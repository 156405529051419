import { Component, EventEmitter, Output } from '@angular/core';
import { InventoryFilter } from '../../models/search.model';
import { FilterOptions } from '../../constants/search.constants';
import { InventoryItemFilterService } from '../../services/app-inventory-filter.service';


@Component({
  selector: 'search-bar',
  moduleId: module.id,
  templateUrl: 'search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent {
  criteriaKeys: string[] = Object.keys(FilterOptions);
  criteria = FilterOptions;
  filters: InventoryFilter[] = [];
  timer: any;
  search: InventoryFilter;
  newFilter: InventoryFilter;
  active = true;
  termed = false;

  constructor(private inventoryFilterService: InventoryItemFilterService) {
    //react to new Chip filter list
    this.inventoryFilterService.getFilterChipListObservable().subscribe((list: InventoryFilter[]) => {
      this.filters = list;
      this.emitModel();
    });

    //set default filters at init
    this.search = new InventoryFilter('', this.criteria.all);
    this.newFilter = new InventoryFilter('', this.criteria.all);
    this.filters.push(this.search);
    this.filters.push(new InventoryFilter('WVCommercial', this.criteria.productOfferingIds));
    this.updateChipListObservable();
  }

  private updateChipListObservable(): void {
    this.inventoryFilterService.updateFilterChipListObservable(this.filters);
  }

  private notInFilterList(): Boolean {
    return this.filters.filter((item) => {
      return (item.searchTerm === this.newFilter.searchTerm) &&
        (item.searchCriteria.id === this.newFilter.searchCriteria.id);
    }).length === 0;
  }

  createFilter() {
    if (this.newFilter.searchTerm.trim().length > 0 && this.notInFilterList()) {
      this.filters.push(new InventoryFilter(this.newFilter.searchTerm, this.newFilter.searchCriteria));
      this.newFilter.searchTerm = '';
      this.updateChipListObservable();
    }
  }

  searchChange() {
    clearTimeout(this.timer);
    this.timer = setTimeout((() => {this.emitModel();}), 500);
  }

  emitModel() {
    let allFilters: InventoryFilter[] = [].concat(this.filters);
    if (this.active === false) {
      allFilters.push(new InventoryFilter('false', {id: 'active', display: ''}));
    }
    if (this.termed === false) {
      allFilters.push(new InventoryFilter('true', {id: 'active', display: ''}));
    }
    this.inventoryFilterService.updateFiltersObservable(allFilters);
  }
}
