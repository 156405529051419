import {Component, Input, OnInit} from '@angular/core';
import {CodeLists, InventoryItem} from '../../types/inventory.interfaces';
import {PanelService} from './panel.service';
import {ServiceCodes} from '../../services/service-code.service';


@Component({
  selector: 'panel-bar',
  templateUrl: 'panel.component.html',
  moduleId: module.id,
  styleUrls: ['./panel.component.scss'],
})

export class PanelComponent implements OnInit {
  @Input() inventoryItem: InventoryItem;

  serviceCodes: CodeLists;
  serviceCodeKeys: String[];
  propertiesColumnOne: String[];
  propertiesColumnTwo: String[];

  constructor(private PanelService: PanelService, private ServiceCodes: ServiceCodes) {
  }


  commaList(property: string): string {
    return this.PanelService.createCommaList(property, this.inventoryItem);
  }

  ngOnInit(): void {
    if (this.inventoryItem) {
      let itemProperties = this.PanelService.sortProperties(this.inventoryItem);
      let halfPropertiesLength = this.PanelService.halfPropertiesLength(itemProperties);
      this.propertiesColumnOne = itemProperties.slice(0, halfPropertiesLength);
      this.propertiesColumnTwo = itemProperties.slice(halfPropertiesLength, itemProperties.length);

      if (this.inventoryItem.serviceCodes) {
        // reformats one array to 'n' number of serviceCode typed arrays (e.g. [...] => { HCPCS: [...], CPT: [...] })
        this.serviceCodes = this.ServiceCodes.updateCodes(this.inventoryItem.serviceCodes);
        this.serviceCodeKeys = Object.keys(this.serviceCodes);
      }
    }
  }
}
