import { Component, OnInit, ViewEncapsulation } from '@angular/core';
//import { version } from '../../../../package.json';
//const { version: appVersion } = require('package.json');
import packageInfo from '../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './vsp.footer.component.html',
  styleUrls: ['./vsp.footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VspFooterComponent implements OnInit {

  /***** START - PUBLIC MEMBERS *****/
  public copyrightText: string;
  public appVersion;

  /***** END - PUBLIC MEMBERS *****/


  /***** START - EVENT HANDLERS *****/
  ngOnInit() {
    const currentDate = new Date();
    this.copyrightText = `${currentDate.getFullYear()} Vision Service Plan. All Rights Reserved.`;
    //this.appVersion = `Version: ${version}`;
    this.appVersion = `${packageInfo.version}`;
  }

  /***** END - EVENT HANDLERS *****/

}