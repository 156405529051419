import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Inventory} from '../../types/inventory.interfaces';
import { Observable } from 'rxjs';

@Injectable()
export class InventoryService {

  constructor(private http: HttpClient) {}

  public getInventory(url: string): Observable<Inventory[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept:  'application/vnd.vsp.inventoryportalitems+json',
        'Content-Type':  'application/json'
      })
    };
    return this.http.get<Inventory[]>(url, httpOptions);
  }
}
