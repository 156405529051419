<div *ngFor="let property of properties">
  <div class="item-property layout-row" *ngIf="property !== 'inventoryName'">
    <h4>{{getDisplayName(property)}}:&nbsp;</h4>
    <div [ngSwitch]="getSwitch(property)" class="h4-values">
      <div *ngSwitchCase="'commaList'" [innerHTML]="commaList(property)"></div>
      <div *ngSwitchCase="'propertyLookup'">{{propertyLookup(property)}}</div>
      <div *ngSwitchCase="'parser'" [innerHTML]="parser(property)"></div>
      <div *ngSwitchCase="'itemEffectPeriod'" class="h4-values">{{getPeriod(inventoryItem.itemEffectPeriod)}}</div>
      <div *ngSwitchCase="'status'">
        <i *ngIf="inventoryItem.active; else elseBlock" class="material-icons green600">check_circle</i>
        <ng-template #elseBlock class="material-icons red600">
         <i class="material-icons red600">error</i>
        </ng-template>
      </div>
      <div *ngSwitchDefault>{{inventoryItem[property]}}</div>
    </div>
  </div>
</div>
