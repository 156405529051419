
    <app-header></app-header>
        <app-authentication></app-authentication>
        <div id="mySpinner" *ngIf="showLoadingIndicator" class="overlay">
            <div class="center">
                <mat-spinner></mat-spinner>
            </div>
        </div>
        <router-outlet></router-outlet>
    <app-footer></app-footer>
