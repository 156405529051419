import {ServiceCode, CodeLists} from '../types/inventory.interfaces';

export class ServiceCodeRange {
  begin: ServiceCode;
  end: ServiceCode;
  codeList: ServiceCode[];

  constructor() {
    this.begin = null;
    this.end = null;
    this.codeList = [];
  }
}

export class ServiceCodeLists {
  lists: CodeLists;
  constructor() {
    this.lists = {};
  }

  addCode(code: ServiceCode) {
    let codeTypeList: ServiceCode[] = this.getList(code);
    if (!codeTypeList) {
      codeTypeList = this.createList(code);
    }
    return codeTypeList.push(code);
  }

  private getList(code: ServiceCode) {
    return this.lists[code.codeType];
  }

  private createList(code: ServiceCode): ServiceCode[] {
    return this.lists[code.codeType] = [];
  }
}
