export const DisplayNames = {
  productCatalogs: 'Product Catalogs',
  inventoryCatalog: 'Inventory Catalog',
  itemEffectPeriod: 'Effective Period',
  serviceCodes: 'Service Codes',
  description: 'Description',
  inventoryType: 'Inventory Type',
  maxUnits: 'Max Units',
  feeSchedules: 'Fee Schedules',
  examinationLevel: 'Examination Level',
  patientType: 'Patient Type',
  lensDesign: 'Lens Design',
  serviceCodeModifiers: 'Modifier',
  lensMaterial: 'Lens Material',
  lensOptionMaterialType: 'Lens Option Material Type',
  contactLensTraits: 'Contact Lens Traits',
  contactLensService: 'Contact Lens Service',
  bilateralInd: 'Bilateral Indicator',
  lensVisionType: 'Lens Vision Type',
  overSize: 'Over Size',
  hiIndex: 'Hi Index',
  midIndex: 'Mid Index',
  lensOptionVisionType: 'Lens Option Vision Type',
  diagnosticConditions: 'Diagnostic Conditions',
  lensPlus: 'Lens Plus',
  category: 'Category',
  surgicalProcedure: 'Surgical Procedure',
  componentCode: 'Component Code',
  includes: 'Includes',
  excludes: 'Excludes',
  productOfferingIds: 'Product Offering Ids',
  active: 'Active'
};

export const SortedProperties = [
  'active',
  'description',
  'serviceCodeModifiers',
  'productCatalogs',
  'inventoryCatalog',
  'maxUnits',
  'itemEffectPeriod',
  'examinationLevel',
  'patientType',
  'inventoryType',
  'feeSchedules',
  'lensDesign',
  'lensMaterial',
  'lensOptionMaterialType',
  'contactLensTraits',
  'contactLensService',
  'bilateralInd',
  'lensVisionType',
  'overSize',
  'hiIndex',
  'midIndex',
  'lensOptionVisionType',
  'diagnosticConditions',
  'lensPlus',
  'category',
  'surgicalProcedure',
  'componentCode',
  'includes',
  'excludes',
  'productOfferingIds'
];

export const InventoryTypes = {
  productCatalogs: {
    SWITCH: 'commaList'
  },
  serviceCodeModifiers: {
    SWITCH: 'commaList'
  },
  feeSchedules: {
    SWITCH: 'commaList'
  },
  includes:  {
    SWITCH: 'commaList'
  },
  excludes:  {
    SWITCH: 'commaList'
  },
  lensVisionType:  {
    SWITCH: 'parser'
  },
  hiIndex: {
    SWITCH: 'parser'
  },
  lensMaterial: {
    SWITCH: 'propertyLookup',
    DISPLAY_PROPERTY: 'name'
  },
  productOfferingIds:  {
    SWITCH: 'commaList'
  },
  active: {
    SWITCH: 'status'
  },
  diagnosticConditions: {
    SWITCH: 'commaList'
  }
};
