import { EnvironmentService } from './../environment/environment.service';
import { Injectable, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, filter, fromEvent, map, Observable, Subscription } from 'rxjs';
import { SpinnerService } from '../spinner/spinner.service';
import { RouterEventsService } from '../router-events/router-events.service';

@Injectable({
    providedIn: 'root'
})
export class RefInvPortalAuthService {
    private env;
    authenticationHeader: HttpHeaders;
    public userToken: string;
    public loggedIn = false;
    private jwtHelperService: JwtHelperService;
    private tokenSubject = new BehaviorSubject<string>('');
    private acsIFrameUrl: string;
    private acsSubscription: Subscription;
    private routerSubscription: Subscription;

    private static readonly LOGIN_COMMAND = {
        type: 'login',
    };

    private static readonly LOGOUT_COMMAND = {
        type: 'logout',
    };

    constructor(
        private environmentService: EnvironmentService,
        private sanitizer: DomSanitizer,
        private spinnerService: SpinnerService,
        private readonly routerEventsService: RouterEventsService,
        private router: Router
    ) {
        this.env = environmentService.getEnvironment();
        this.jwtHelperService = new JwtHelperService();
        this.acsIFrameUrl = this.sanitizer.sanitize(
          SecurityContext.URL,
          [
            this.env.oauthApiUrl,
            '/acs2/login?client_id=',
            this.env.oauthClientId,
            '&scope=',
            this.env.oauthScopes,
            '&redirect_uri=',
            this.env.oauthRedirectUrl
          ].join('')
        );

        this.tokenSubject.subscribe((userToken) => {
            this.userToken = userToken;
          });
    }

    private static executeAction(commandMessage): void {
        const acsApiElement: HTMLIFrameElement = document.getElementById(
          'acs-api'
        ) as HTMLIFrameElement;
        const acsApiIframe = acsApiElement.contentWindow;
        acsApiIframe.postMessage(JSON.stringify(commandMessage), '*');
    }

    setACSUrl(path: string): void {
      this.acsIFrameUrl = this.sanitizer.sanitize(
        SecurityContext.URL,
        [
          this.env.oauthApiUrl,
          '/acs2/login?client_id=',
          this.env.oauthClientId,
          '&scope=',
          this.env.oauthScopes,
          '&redirect_uri=',
          path
        ].join('')
      );
    }

    public login(path: string){
        // saves path for redirecting after login
        if (sessionStorage) {
            sessionStorage.setItem('login_redirect', path);
        }
        RefInvPortalAuthService.executeAction(RefInvPortalAuthService.LOGIN_COMMAND);
    }

    public logout(): void {

        if (this.acsSubscription) {
            this.acsSubscription.unsubscribe();
        }
        this.userToken  = null;
        this.tokenSubject.next('');
        this.loggedIn = false;
        RefInvPortalAuthService.executeAction(RefInvPortalAuthService.LOGOUT_COMMAND);
        this.router.navigate(['/']);
        this.login('/');

    }

    public isAuthenticated(): boolean {
        // Check whether the token is expired and return result
        return !this.jwtHelperService.isTokenExpired(this.userToken);
      }
    
    public receiveToken(token: string): void {
        this.userToken = token;
        this.tokenSubject.next(token);
        this.loggedIn = true;
        // TODO: add ability to restrict user groups
        this.authenticationHeader = new HttpHeaders({
          Authorization: this.userToken
        });
      }

  /*
   * This method checks if the user landed on this page via a page load or a router link.
   * If coming from a router link would already have the ACS iframe initiated so we shouldn't wait around for a
   * message. Instead since we already checked that the user is not logged in we know they need to be authenticated still.
   * If coming from a page load we need to wait for the ACS iframe to be initialized and check if it comes back with
   * a token or a blank, which will indicate if we are authenticated or not.
   */
  public checkAuthentication(url?: string): Observable<boolean> {
    this.spinnerService.updateShowLoadingIndicator(true);
    return new Observable((observer) => {
        this.routerSubscription = this.routerEventsService.routeChanged.subscribe((updatedRoutes) => {
            // check if navigating via routerlink, if so then login since an acs iFrame message won't happen
            if (updatedRoutes.prevRoute !== '/oauth_callback') {
                this.login(url);
                observer.next(false);
                observer.complete();
            }
        });

      // this is a navigation via page load, check if iFrame message has been recieved and has a token
      // the iFrame message will have an origin of our oauth link, we can filter by that to ensure we have the right message
      const msgEvent = this.retrieveWindowMessageEvent();

      // the right iFrame message should have the same origin as that of the oauth link
      const eventData = this.filterByOrigin(msgEvent);

      // we need to save the subscription so we can unsubscribe on logout
      this.acsSubscription = eventData.subscribe((token: string) => {
        if (token) {
            if (typeof token === 'string') {
              if (this.routerSubscription) {
                  this.routerSubscription.unsubscribe();
              }
              this.receiveToken(token);
              this.spinnerService.updateShowLoadingIndicator(false);
              observer.next(true);
              observer.complete();
          }
        } else {
          this.login(url);
          observer.next(false);
          observer.complete();
        }
      });
    });
  }

  
  private retrieveWindowMessageEvent(){
    return fromEvent(window, 'message');
  }

  // filter window event by origin of OAuth API URL
  private filterByOrigin(windowEvent: Observable<any>){
    return windowEvent.pipe(
      filter(({ origin }: Partial<MessageEvent> = { origin: '' }) =>
        origin.includes(this.env.oauthApiUrl)
      ),
      map((event) => event.data)
    );
  }
}
