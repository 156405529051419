<div class="search-bar">
  <header>
    <mat-icon>filter_list</mat-icon>
    <h3>Filter</h3>
  </header>

  <mat-form-field>
    <input matInput placeholder="Search"
           [(ngModel)]="search.searchTerm"
           (ngModelChange)="searchChange()">
  </mat-form-field>

  <header><h3>Status</h3></header>
  <div>
    <mat-checkbox id="active" [(ngModel)]="active" (change)="emitModel()">Active</mat-checkbox>
    <mat-checkbox [(ngModel)]="termed" (change)="emitModel()">Termed</mat-checkbox>
  </div>

  <header><h3>Custom Filters</h3></header>
  <mat-form-field>
    <input matInput placeholder="Filter"
           [(ngModel)]="newFilter.searchTerm"
           (keyup.enter)="createFilter()">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Filter</mat-label>
    <select matNativeControl [(ngModel)]="newFilter.searchCriteria">
      <option *ngFor="let key of criteriaKeys" [ngValue]="criteria[key]">{{criteria[key].display}}</option>
    </select>
  </mat-form-field>

  <button style="margin-bottom: 15px" mat-button (click)="createFilter()">APPLY</button>
</div>
