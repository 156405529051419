import {Component, Inject, InjectionToken, Input, OnInit} from '@angular/core';
import {PanelService} from '../panel/panel.service';
import {CodeLists, InventoryItem, InventoryItemPeriod} from '../../types/inventory.interfaces';
import {DisplayNames} from '../../constants/inventoryItem.constants';
import {ServiceCodes} from '../../services/service-code.service';

let nameToken = new InjectionToken('inventory.names');

@Component({
  selector: 'item-properties',
  templateUrl: 'itemProperties.component.html',
  providers: [{provide: nameToken, useValue: DisplayNames}],
  moduleId: module.id,
  styleUrls: ['./itemProperties.component.scss']
})

// todo fix LensSV
export class ItemPropertiesComponent implements OnInit {
  serviceCodes: CodeLists;
  serviceCodeKeys: String[];
  @Input() inventoryItem: InventoryItem;
  @Input() properties: string[];

  constructor(@Inject(nameToken) private displayNames: any, private PanelService: PanelService, private ServiceCodes: ServiceCodes) {}

  getDisplayName(property: string): string {
    return this.displayNames[property] ? this.displayNames[property] : property;
  }

  commaList(property: string): string {
    return this.PanelService.createCommaList(property, this.inventoryItem);
  }

  parser(property: string): string {
    return this.PanelService.parseProperty(property, this.inventoryItem);
  }

  propertyLookup(property: string): string {
    return this.PanelService.propertyLookup(property, this.inventoryItem);
  }

  getPeriod(period: InventoryItemPeriod) {
    return this.PanelService.getPeriod(period);
  }

  getSwitch(property: string): string {
    return this.PanelService.getSwitch(property);
  }

  ngOnInit() {
    if (this.inventoryItem && this.inventoryItem.serviceCodes) {
      // reformats one array to 'n' number of serviceCode typed arrays (e.g. [...] => { HCPCS: [...], CPT: [...] })
      this.serviceCodes = this.ServiceCodes.updateCodes(this.inventoryItem.serviceCodes);
      this.serviceCodeKeys = Object.keys(this.serviceCodes);
    }
  }
}
