import {Component, ElementRef, Input, QueryList, ViewChild, ViewChildren} from '@angular/core';
import { InventoryFilter } from "../../models/search.model";
import { InventoryItemFilterService } from '../../services/app-inventory-filter.service';
import { MatChip, MatChipList } from '@angular/material/chips';


@Component({
  selector: 'app-filter-chip-list',
  templateUrl: './filter-chip-list.component.html',
  styleUrls: ['./filter-chip-list.component.scss']
})
export class FilterChipListComponent {
  @ViewChild(MatChipList, {read: ElementRef, static: false}) matChipList: ElementRef;
  @ViewChildren(MatChip, {read: ElementRef}) matChips: QueryList<ElementRef>;

  filters: InventoryFilter[] = [];
  buttonText = "";
  hide = true;
  showButton = false;

  constructor(private inventoryFilterService: InventoryItemFilterService) {
    this.inventoryFilterService.getFilterChipListObservable().subscribe((list: InventoryFilter[]) => {
      this.filters = list;
      this.updateShowHideToggle();
    });
  }

  deleteFilter(index: number) {
    this.filters.splice(index, 1);
    this.emitModel();
    this.updateShowHideToggle();
  }

  toggleChips() {
    this.hide = !this.hide;
    this.setShowHideText();
  }

  private emitModel() {
    this.inventoryFilterService.updateFilterChipListObservable(this.filters);
  }

  private updateShowHideVisibility() {
    this.showButton = this.getHiddenChipCount() > 0;
  }

  private setShowHideText(): void {
    this.buttonText = this.hide ? `SHOW +${this.getHiddenChipCount()}` : 'HIDE';
  }

  private getHiddenChipCount(): number {
    const marginBuffer = 10;
    let count = 0;
    let maxWidth = this._getMaxWidth();
    /* Count all the chips that exceed the width given to chip list */

    this.matChips.forEach((chip) => {
      maxWidth -= (chip.nativeElement.getBoundingClientRect().width + marginBuffer);
      if (maxWidth < 0) {
        count++;
      }
    });

    return count;
  }

  private _getMaxWidth(): number {
    return this.matChipList.nativeElement.getBoundingClientRect().width;
  }

  private updateShowHideToggle() {
    requestAnimationFrame(() => {
      this.updateShowHideVisibility();
      this.setShowHideText();
    });
  }
}
