import { RefInvPortalAuthService } from './../../services/authentication/ref-inv-portal-auth.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EnvironmentService } from 'src/app/services/environment/environment.service';

@Component({
    selector: 'app-authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
    private env;
    sanitizedUrl: SafeResourceUrl;
    constructor(private environmentService: EnvironmentService, private authService: RefInvPortalAuthService, private sanitizer: DomSanitizer) {}

    public ngOnInit(): void {
        this.env = this.environmentService.getEnvironment();
        this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            [
                this.env.oauthApiUrl, '/acs2/iframe?client_id=',
                this.env.oauthClientId, '&scope=',
                this.env.oauthScopes, '&redirect_uri=',
                this.env.oauthRedirectUrl
            ].join('')
        );
    }

    @HostListener('window:message', ['$event'])
    receiveToken(event: MessageEvent): void {
        if (String(event.data).indexOf('Bearer') === -1) {
            return;
        }
        this.authService.receiveToken(<string>event.data);
    }
}
