import { RefInvPortalAuthService } from './ref-inv-portal-auth.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class OauthRedirectGuardService implements CanActivate {
  newPath: string;
  constructor(private router: Router, private authenticationService: RefInvPortalAuthService) {
    this.newPath = '/';
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {
    if (sessionStorage && sessionStorage.getItem('login_redirect')) {
      this.newPath = sessionStorage.getItem('login_redirect');
      sessionStorage.removeItem('login_redirect');
    }
    this.router.navigateByUrl(this.newPath);
    return true;
  }
}
