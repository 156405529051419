import { EnvironmentService } from './services/environment/environment.service';
import { OauthRedirectGuardService } from './services/authentication/oauth-redirect-guard.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { AppComponent } from './app.component';
import { InventoryService } from './services/inventoryService/inventory.service';
import { LocalDatabase } from './services/local-database.service';
import { FormsModule } from '@angular/forms';
import { PanelModule } from './components/panel/panel.module';
import { SearchModule } from './components/search/search.module';
import { InventoryItemFilterService } from './services/app-inventory-filter.service';
import { CommonModule } from '@angular/common';
import { environment } from '../environments/environment';
import { HeaderComponent } from "./components/header/header.component";
import { VspFooterComponent } from "./components/footer/vsp.footer.component";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MaterialsModule } from "./materials.module";
import { MatPaginatorModule } from '@angular/material/paginator';
import { FilterChipListComponent } from "./components/filter-chip-list/filter-chip-list.component";
import { AppRoutingModule, routes } from 'src/app/app-routing.module';
import { AuthGuardService } from 'src/app/services/authentication/auth-guard.service';
import { RouterEventsService } from 'src/app/services/router-events/router-events.service';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpinnerInterceptorService } from 'src/app/services/spinner/spinner.interceptor.service';
import { InventoryComponent } from './components/inventory/inventory.component';
import { RefInvPortalAuthService } from './services/authentication/ref-inv-portal-auth.service';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { ApiHeadersInterceptor } from 'src/app/interceptors/api-headers.interceptor';

@NgModule({
  declarations: [
    AppComponent, HeaderComponent, AuthenticationComponent, VspFooterComponent, InventoryComponent, FilterChipListComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    MaterialsModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    PanelModule,
    RouterModule.forRoot(routes),
    SearchModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiHeadersInterceptor, multi: true },
    EnvironmentService,
    InventoryService,
    InventoryItemFilterService,
    LocalDatabase,
    { provide: Window, useValue: window },
    { provide: environment, useValue: environment },
    AuthGuardService,
    OauthRedirectGuardService,
    RefInvPortalAuthService,
    RouterEventsService,
    EnvironmentService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
