import { Injectable } from '@angular/core';
import { Inventory } from '../types/inventory.interfaces';
import { InventoryFilter } from '../models/search.model';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class InventoryItemFilterService {
  private filterFunctions: { [key: string]: any };
  private filtersChipListObservable: ReplaySubject<InventoryFilter[]> = new ReplaySubject(1);
  private filtersObservable: ReplaySubject<InventoryFilter[]> = new ReplaySubject(1);

  static objectValues(item: Inventory) {
    return this.iterate(item, []).join(' ');
  }

  static iterate(item: any, values: string[]) {
    if (Array.isArray(item)) {
      for (let i = 0; i < item.length; i++) {
        this.iterate(item[i], values);
      }
    } else if (typeof item === 'object') {
      for (let property in item) {
        if (item.hasOwnProperty(property)) {
          this.iterate(item[property], values);
        }
      }
    } else {
      values.push(item);
    }
    return values;
  }

  constructor() {
    this.filterFunctions = {
      all: this.filterAll,
      inventoryName: this.filterSingleValue.bind(this, 'inventoryName'),
      inventoryCatalog: this.filterSingleValue.bind(this, 'inventoryCatalog'),
      productCatalogs: this.filterList.bind(this, null, 'productCatalogs'),
      modifiers: this.filterList.bind(this, null, 'serviceCodeModifiers'),
      serviceCodes: this.filterList.bind(this, 'code', 'serviceCodes'),
      productOfferingIds: this.filterList.bind(this, null, 'productOfferingIds'),
      active: this.filterSingleValue.bind(this, 'active')
    };
  }

  public filterItems(collection: any, filters: InventoryFilter[]) {
    let collectionChain = collection.chain();
    for (let i = 0; i < filters.length; i++) {
      let filterFunction = this.filterFunctions[filters[i].searchCriteria.id];
      filterFunction(collectionChain, filters[i].searchTerm);
    }
    return collectionChain.simplesort('inventoryName').data();
  }

  private filterAll(collectionChain: any, search: string) {
    return collectionChain.where((item: any) => {
      let regEx = new RegExp(search, 'i');
      let itemValues: string = InventoryItemFilterService.objectValues(item);
      return regEx.test(itemValues);
    });
  }

  private filterSingleValue(propertyName: string, collectionChain: any, search: string) {
    let findByProperty: { [key: string]: any } = {};
    findByProperty[propertyName] = {$regex: [search, 'i']};
    return collectionChain.find(findByProperty);
  }

  private filterList(lookupKey: string, itemProperty: string, collectionChain: any, search: string) {
    return collectionChain.where((item: any) => {
      let showItem = false;
      if (item.hasOwnProperty(itemProperty)) {
        let regEx = new RegExp(search, 'i');
        showItem = regEx.test(item[itemProperty].map((value: { [key: string]: any }) => {
          return lookupKey ? value[lookupKey] : value;
        }).join(' '));
      }
      return showItem;
    });
  }

  public getFiltersObservable(): ReplaySubject<InventoryFilter[]> {
    return this.filtersObservable;
  }

  public updateFiltersObservable(list: InventoryFilter[]) {
    this.filtersObservable.next(list);
  }

  public getFilterChipListObservable(): ReplaySubject<InventoryFilter[]> {
    return this.filtersChipListObservable;
  }

  public updateFilterChipListObservable(list: InventoryFilter[]) {
    this.filtersChipListObservable.next(list);
  }
}
