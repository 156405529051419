import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { InventoryComponent } from './components/inventory/inventory.component';
import { AuthGuardService } from './services/authentication/auth-guard.service';
import { OauthRedirectGuardService } from './services/authentication/oauth-redirect-guard.service';

export const routes: Routes = [
    { path: '', redirectTo: '/inventory', pathMatch: 'full'},
    {
        path: 'inventory',
        component: InventoryComponent,
        data: {},
        canActivate: [AuthGuardService],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'oauth_callback',
        component: InventoryComponent,
        canActivate: [OauthRedirectGuardService],
        runGuardsAndResolvers: 'always'
    },
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [],
    exports: [RouterModule]
})
export class AppRoutingModule { }