import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RefInvPortalAuthService } from '../services/authentication/ref-inv-portal-auth.service';
import { EnvironmentService } from '../services/environment/environment.service';

@Injectable()
export class ApiHeadersInterceptor implements HttpInterceptor {
    constructor(private authService: RefInvPortalAuthService, private environmentService: EnvironmentService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let targetRequest: HttpRequest<unknown> = request;

        if (request.url.startsWith(this.environmentService.getEnvironment().API)) {
            targetRequest = this.addAuthorizationHeader(request);
        }

        return next.handle(targetRequest);
    }

    private addAuthorizationHeader(request: HttpRequest<unknown>): HttpRequest<unknown> {
        // Clone the request and replace the original headers with
        // cloned headers, updated with the authorization.
        return request.clone({
            headers: request.headers.set('Authorization', this.authService.userToken)
        });
    }
}
