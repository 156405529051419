import { EnvironmentService } from './../../services/environment/environment.service';
import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatDrawer } from '@angular/material/sidenav';
import { Router, NavigationStart } from '@angular/router';
import { Subscription, debounceTime, filter } from 'rxjs';
import { InventoryFilter } from 'src/app/models/search.model';
import { InventoryService } from 'src/app/services/inventoryService/inventory.service';
import { LocalDatabase } from 'src/app/services/local-database.service';
import { Inventory } from 'src/app/types/inventory.interfaces';
import { InventoryItemFilterService } from '../../services/app-inventory-filter.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {
  public showLoadingIndicator = false;
  public routerSubscription = new Subscription();
  private env;
  inventoryItems: Inventory[];
  displayInventoryItems: Inventory[];
  @Input() drawer: MatDrawer;

  @ViewChild(MatPaginator, {static: true})
  paginator: MatPaginator;
  pageSize = 20;
  pageIndex = 0;
  pageSizeOptions: number[] = [20, 50, 100];

  constructor(
              public router: Router,
              public spinnerService: SpinnerService,
              private environmentService: EnvironmentService,
              private inventoryService: InventoryService,
              private localDatabase: LocalDatabase,
              private paginatorIntl: MatPaginatorIntl,
              private inventoryFilterService: InventoryItemFilterService
            ) {
    this.paginatorIntl.itemsPerPageLabel = 'Rows per page:';
    spinnerService.showLoadingIndicator$
    .pipe(debounceTime(500))
    .subscribe((showLoadingIndicator) => {
      this.showLoadingIndicator = showLoadingIndicator;
    });

  }

  ngOnInit(): void {
    this.env = this.environmentService.getEnvironment();
    //listen to any filter change
    this.inventoryFilterService.getFiltersObservable().subscribe((filters: InventoryFilter[]) => {
      this.handleUpdateFilters(filters);
    });

    //get data from API
    this.inventoryService.getInventory(this.env.API).subscribe((inventoryItems: Inventory[]) => {
        this.localDatabase.loadCollection(inventoryItems);
        this.inventoryItems = this.localDatabase.getCollection().chain().data();
      },
      (error) => {
        console.error('Error with GET request:');
        console.error(error);
      });
  }

  changePage(event: PageEvent) {
    this.setDisplayInventoryItems(event.pageIndex, event.pageSize);
  }

  private handleUpdateFilters(filters: InventoryFilter[]) {
    this.inventoryItems = this.inventoryFilterService.filterItems(this.localDatabase.getCollection(), filters);
    this.paginator.pageIndex = 0;
    this.setDisplayInventoryItems(this.pageIndex, this.pageSize);
  }

  getInventoryItemsLength(): number {
    return this.inventoryItems ? this.inventoryItems.length : 0;
  }

  private setDisplayInventoryItems(pageIndex: number, size: number) {
    let currentIndex = pageIndex * size;
    this.displayInventoryItems = this.inventoryItems.slice(currentIndex, currentIndex + size);
  }
}
