<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title title="{{inventoryItem.inventoryName}}">{{inventoryItem.inventoryName}}</mat-panel-title>
      <div class="service-codes-header layout-row">
        <mat-panel-description *ngFor="let codeType of serviceCodeKeys">
          <em>{{codeType}}:&nbsp;</em>
          <em *ngFor="let code of serviceCodes[codeType]" [innerHTML]="code.template">|</em>
          <span *ngIf="inventoryItem.serviceCodeModifiers?.length">
          <span>|</span>
          <em [innerHTML]="commaList('serviceCodeModifiers')" class="comma-list"></em>
        </span>
        </mat-panel-description>
      </div>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div class="layout-row">
        <item-properties [inventoryItem]="inventoryItem" [properties]="propertiesColumnOne"></item-properties>
        <item-properties [inventoryItem]="inventoryItem" [properties]="propertiesColumnTwo"></item-properties>
      </div>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
