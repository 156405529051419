import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SpinnerService } from './spinner.service';
import { tap, finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpinnerInterceptorService implements HttpInterceptor {
  count = 0;
  constructor(public spinnerService: SpinnerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // All HTTP requests are going to go through this method
    this.count++;
    this.spinnerService.updateShowLoadingIndicator(true);
    return next.handle(req).pipe(tap({
      next: event => console.log(event),
      error: error => console.log(error)
    }), finalize(() => {
          this.count--;
          if ( this.count === 0 ) {
            this.spinnerService.updateShowLoadingIndicator(false);
          }
        })
      );
  }
}
