import {Injectable, Inject, InjectionToken} from '@angular/core';
import {InventoryItem, InventoryItemPeriod} from '../../types/inventory.interfaces';

export const InventoryTypesToken = new InjectionToken('inventory.types');
export const SortedPropertiesToken = new InjectionToken('inventory.sortedProperties');


@Injectable()
export class PanelService {
  parsers: any;
  constructor(@Inject(InventoryTypesToken) private displayTypes: any,
              @Inject(SortedPropertiesToken) private propertiesList: string[]) {
    this.parsers = {
      hiIndex: this.hiIndexParser,
      lensVisionType: this.lensVisionTypeParser
    };
  }

  parseProperty(property: string, inventoryItem: InventoryItem) {
    return this.parsers[property](inventoryItem[property]);
  }

  hiIndexParser(hiIndex: any) {
    if (hiIndex.rangeLowValue && hiIndex.rangeHighValue) {
      return hiIndex.rangeLowValue + '-' + hiIndex.rangeHighValue;
    } else {
      return hiIndex.rangeLowValue ? hiIndex.rangeLowValue : hiIndex.rangeHighValue;
    }
  }

  lensVisionTypeParser(lensVisionType: any) {
    const segmentedDisplay: any = {
      powerSplit: 'Power Split',
      segmentSize: 'Segment Size',
      shapeType: 'Shape Type'
    };

    if (lensVisionType.hasOwnProperty('name')) {
      return lensVisionType.name;
    } else {
      let segmentedList: any = [];
      for (let prop in lensVisionType.segmentedMultifocal) {
        if (lensVisionType.segmentedMultifocal.hasOwnProperty(prop)) {
          let propertyTemplate = [
            '<div class="lens-vision-type">',
            '<h5>',
            segmentedDisplay[prop],
            '</h5>',
            lensVisionType.segmentedMultifocal[prop],
            '</div>'
          ].join('');
          segmentedList.push(propertyTemplate);
        }
      }
      return segmentedList.join('');
    }
  }

  createCommaList(property: string, inventoryItem: InventoryItem) {
    let tooltip: boolean = this.displayTypes[property].TOOLTIP;
    let valueArr: any = inventoryItem[property];
    let getValue: any = this.getValueGetter(property);

    if (valueArr === undefined) {
      return '';
    }

    return valueArr.map((value: any, index: number) => {
      let parsedValue = (index < valueArr.length - 1) ? getValue(value) : getValue(value);
      let period = tooltip ? value.itemEffectPeriod : null;
      return this.convertToHTML(parsedValue, period);
    }).join(', ');
  }

  convertToHTML(value: string[], period?: InventoryItemPeriod) {
    return [this.getSpanTag(period), value, '</span>'].join('');
  }

  getSpanTag(period: InventoryItemPeriod) {
    return period ? '<span class="vsp-tooltip" title="' + this.getPeriod(period) + '">' : '<span>';
  }

  getPeriod(period: InventoryItemPeriod) {
    return period.begin + '-' + period.end;
  }

  propertyLookup(property: string, inventoryItem: InventoryItem) {
    let displayProperty = this.displayTypes[property].DISPLAY_PROPERTY;
    return inventoryItem[property][displayProperty];
  }

  getValueGetter(property: string) {
    let displayProperty = this.displayTypes[property].DISPLAY_PROPERTY;
    if (displayProperty) {
      return (value: string) => value[displayProperty];
    } else {
      return (value: string) => value;
    }
  }

  getSwitch(property: string) {
    let switchCase: string;
    if (this.displayTypes[property]) {
      switchCase = this.displayTypes[property].SWITCH;
    } else {
      switchCase = property;
    }
    return switchCase;
  }

  sortProperties(inventoryItem: InventoryItem): string[] {
    let itemKeys = Object.keys(inventoryItem);
    let sortedProperties: string[] = [];

    // iterates properties of item and inserts into sortedProperties with specified ordering from the propertiesList
    for (let i = 0; i < itemKeys.length; i++) {
      let key = itemKeys[i];
      let index = this.propertiesList.indexOf(key);
      if (index > -1) {
        sortedProperties[index] = key;
      }
    }
    // filters out undefined values in array (e.g. ["description", "serviceCodes",  undefined × 18, "componentCode"])
    return sortedProperties.filter((property: any) => typeof property === 'string');
  }

  halfPropertiesLength(properties: string[]): number {
    // evenly divides array in half if even...otherwise the odd property will be added to the first column
    return properties.length % 2 === 0 ? properties.length / 2 : properties.length / 2 + 1;
  }
}
