import {NgModule} from '@angular/core';
import {PanelService, InventoryTypesToken, SortedPropertiesToken} from './panel.service';
import {PanelComponent} from './panel.component';
import {InventoryTypes, SortedProperties} from '../../constants/inventoryItem.constants';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {ItemPropertiesModule} from '../itemProperties/itemProperties.module';
import {ServiceCodes} from '../../services/service-code.service';
import {MaterialsModule} from "../../materials.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

@NgModule({
  imports: [CommonModule, FormsModule, ItemPropertiesModule, MaterialsModule, BrowserAnimationsModule],
  exports: [PanelComponent],
  declarations: [PanelComponent],
  providers: [
    PanelService,
    ServiceCodes,
    {provide: InventoryTypesToken, useValue: InventoryTypes},
    {provide: SortedPropertiesToken, useValue: SortedProperties}
    ]
})

export class PanelModule {}
