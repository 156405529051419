import {Injectable} from '@angular/core';

let loki = require('lokijs/src/lokijs.js');
let database = new loki('inventory');
let collection = database.addCollection('items');

@Injectable()
export class LocalDatabase {
  loadCollection(items: any) {
    collection.data = items;
  }

  getCollection() {
    return collection;
  }
}
