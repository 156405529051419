import { Component, Inject, OnDestroy } from '@angular/core';
import { environment } from '../environments/environment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterEventsService } from './services/router-events/router-events.service';
import { NavigationStart, Router } from '@angular/router';
import { debounceTime, filter, Subscription } from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'sd-app',
  moduleId: module.id,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnDestroy {
  public showLoadingIndicator = false;
  public routerSubscription = new Subscription();
  constructor(
              private iconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private readonly routerEventsService: RouterEventsService,
              public router: Router,
              @Inject(environment) private config: any,
              public spinnerService: SpinnerService
            ) {
    this.registerIcons();
    
    this.spinnerService.showLoadingIndicator$.pipe(debounceTime(500)).subscribe((showLoadingIndicator) => {
      this.showLoadingIndicator = showLoadingIndicator;
    });

    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationStart)).subscribe(event => {
        this.routerEventsService.updateCurrentRoute((event as NavigationStart).url);
      });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  registerIcons(){
    // Register the icon sets with the MatIconRegistry so that the namespaces are accessible in the DOM
    this.iconRegistry.addSvgIconSetInNamespace(
      'vsp-icons',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/vsp.icons.svg'),
    );
    this.iconRegistry.addSvgIconSetInNamespace(
      'vsp-branding-images',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/vsp.branding-images.svg'),
    );
    this.iconRegistry.addSvgIcon(
      'vspVisionLogo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/VSP_Vision_Logotype_RGB.svg'),
    );
    this.iconRegistry.getNamedSvgIcon('vspVisionLogo').subscribe({
      complete: () => {
       //do nothing -- the vspVisionLogo icon is available for the MatIconRegistry
      },
      error: () => {
        console.error("The vspVisionLogo icon isn't available for the MatIconRegistry.");
      },
    });
  }
}
