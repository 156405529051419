import {ServiceCode, CodeLists} from '../types/inventory.interfaces';
import {Injectable} from '@angular/core';
import {ServiceCodeRange, ServiceCodeLists} from '../models/serviceCode.model';
import {PanelService} from '../components/panel/panel.service';
/**
 * Created by rossra on 8/19/2016.
 */

@Injectable()
export class ServiceCodes {

  static convertToNumber(str: string) {
    return Number(str.replace(/\D+/g, ''));
  }

  constructor(private PanelService: PanelService) {}

  updateCodes(codes: any) {
    let serviceCodes: ServiceCodeLists = new ServiceCodeLists();
    for (let i = 0; i < codes.length; i++) {
      serviceCodes.addCode(codes[i]);
    }
    return this.sortLists(serviceCodes.lists);
  }

  isConsecutive(firstNum: number, secondNum: number) {
    return firstNum + 1 === secondNum;
  }

  setRanges(codeArr: Array<any>) {
    let serviceCodeRange = new ServiceCodeRange();
    let list: any = [];
    for (let i = 0; i < codeArr.length; i++) {
      let code = codeArr[i];
      let codeNum = ServiceCodes.convertToNumber(codeArr[i].code);
      let nextCodeNum = codeArr[i + 1] && ServiceCodes.convertToNumber(codeArr[i + 1].code);

      if (this.isConsecutive(codeNum, nextCodeNum)) {
        if (!serviceCodeRange.begin) {
          serviceCodeRange.begin = code;
        }
        serviceCodeRange.codeList.push(code);
      } else if (serviceCodeRange.begin) {
        serviceCodeRange.end = code;
        list.push(serviceCodeRange);
        serviceCodeRange.codeList.push(code);
        serviceCodeRange = new ServiceCodeRange();
      } else {
        list.push(code);
      }
    }
    return list;
  }

  formatRanges(codeArr: any) {
    let that = this;
    let formatter: any = {
      range: (serviceCodeRange: ServiceCodeRange) => '<span>' + serviceCodeRange.begin.code + '-' + serviceCodeRange.end.code + '</span>',
      single: (code: ServiceCode) => that.PanelService.getSpanTag(code.effectivePeriod) + code.code + '</span>'
    };
    return codeArr.map((code: any, index: number) => {
      code.template = code instanceof ServiceCodeRange ? formatter.range(code) : formatter.single(code);
      if (index < codeArr.length - 1) {
        code.template += ', ';
      }

      return code;
    });
  }

  public sortLists(codeLists: CodeLists) {
    for (let codeType in codeLists) {
      if (codeLists.hasOwnProperty(codeType)) {
        codeLists[codeType].sort((codeObj1: any, codeObj2: any) =>
          ServiceCodes.convertToNumber(codeObj1.code) - ServiceCodes.convertToNumber(codeObj2.code));

        codeLists[codeType] = this.setRanges(codeLists[codeType]);
        codeLists[codeType] = this.formatRanges(codeLists[codeType]);
      }
    }
    return codeLists;
  }
}
