<mat-chip-list [class.hide]="hide" class="layout-row">
  <mat-chip *ngFor="let filter of filters | slice : 1; let i = index"
            [removable]="true"
            (removed)="deleteFilter(i + 1)"
            class="layout-row">
      <div style="margin-right: 5px">{{filter.searchCriteria.display}}:</div>
      <div>{{filter.searchTerm}}</div>
    <mat-icon matChipRemove>cancel</mat-icon>
  </mat-chip>
  <button *ngIf="showButton" class="chip-list-button" [class.show-less-button]="hide" mat-button
          (click)="toggleChips()">{{buttonText}}
  </button>
</mat-chip-list>

