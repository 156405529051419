/**
 * Created by rossra on 9/8/2016.
 */
export const FilterOptions = {
  all: {id: 'all', display: 'All'},
  serviceCodes: {id: 'serviceCodes', display: 'Service Codes'},
  inventoryName: {id: 'inventoryName', display: 'Inventory Name'},
  inventoryCatalog: {id: 'inventoryCatalog', display: 'Inventory Catalog'},
  productCatalogs: {id: 'productCatalogs', display: 'Product Catalogs'},
  modifiers: {id: 'modifiers', display: 'Modifiers'},
  productOfferingIds: {id: 'productOfferingIds', display: 'Product Offering Id'}
};
