import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterEventsService {
  routes = {
    prevRoute: '/oauth_callback',
    currentRoute: '/oauth_callback'
  };

  public routeChanged = new BehaviorSubject<any>(this.routes);

  constructor() {
    //do nothing
  }

  public updateCurrentRoute(newRoute: string): void {
    this.routes.prevRoute = this.routes.currentRoute;
    this.routes.currentRoute = newRoute;
    this.routeChanged.next(this.routes);
  }
}