import {SearchComponent} from './search.component';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MaterialsModule} from "../../materials.module";

@NgModule({
  imports: [FormsModule, MaterialsModule],
  exports: [SearchComponent],
  declarations: [SearchComponent]
})

export class SearchModule {}
