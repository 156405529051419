import { EnvironmentService } from './../../services/environment/environment.service';
import { RefInvPortalAuthService } from './../../services/authentication/ref-inv-portal-auth.service';
import { Component, ViewEncapsulation, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  private static readonly IS_LOGOUT_ENABLED: string = 'isLogoutEnabled';
  private logoutEnabled: boolean;
  constructor(
    private readonly refInvPortalAuthService: RefInvPortalAuthService,
    private environmentService: EnvironmentService
  ){
  }

  isLogoutEnabled(): boolean {
    return this.logoutEnabled;
  }

  ngOnInit(): void {
    this.logoutEnabled = this.checkIfLogoutIsEnabled();
  }

  checkIfLogoutIsEnabled(): boolean {
    let env = this.environmentService.getEnvironment();
    return env[HeaderComponent.IS_LOGOUT_ENABLED] && env[HeaderComponent.IS_LOGOUT_ENABLED] === true;
  }
  
  logout(): void {
    this.refInvPortalAuthService.logout();
  }
}
